<template>
  <div>
    <v-container color="primary">
      <v-card icon="clipboard-text" title="Subscriptions" class="px-5 py-10">
        <v-btn color="success" class="mr-0" @click="Adddialog = true">
          Add Subscription
        </v-btn>
        <v-dialog v-model="Adddialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Add Subscription for customer</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="AddSubscriptionForm" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="addSubscriptionModel.subscriptionPackage" :items="subscriptionPackages"
                        :rules="[rules.required]" label="Subscription Package" item-text="packageName" item-value="id"
                        return-object>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="addSubscriptionModel.subscriptionPeriod" :items="subscriptionPeriods"
                        :rules="[rules.required]" label="Subscription Period" @change="selectedPeriodChanged"
                        item-text="text" item-value="value">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu ref="amenu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="addSubscriptionModel.startDate" label="Picker in menu"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" @change="addDateChanged"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="addSubscriptionModel.startDate" color="green lighten-1" no-title
                          scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="amenu = false">
                            Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.amenu.save(date)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-alert>
                        END Date: <b>{{ getDate(aevalEndDate) }}</b>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field id="LegalName" class="green-input" v-model="addSubscriptionModel.legalName"
                        label="Legal Name" :rules="[rules.required]" type="text" />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field id="CommercialName" class="green-input" v-model="addSubscriptionModel.commercialName"
                        label="Commercial name" :rules="[rules.required]" type="text" />
                    </v-col>

                    <!-- <v-col cols="12" md="4">
                      <v-select v-model="addSubscriptionModel.legalStructure" :items="legalStructureOptions"
                        :rules="[(v) => !!v || 'Item is required']" label="Legal Structure" item-text="englishName"
                        item-value="id" required>
                      </v-select>
                    </v-col> -->

                    <v-col cols="6" md="4">
                      <v-text-field id="txtYearOfEstablishment" class="green-input"
                        v-model="addSubscriptionModel.yearOfEstablishment" label="Establishment Year"
                        :rules="[rules.required, rules.yearRule]" type="number" />
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-text-field id="txtWebSite" class="green-input" v-model="addSubscriptionModel.webSite"
                        label="Website" placeholder="N/A" type="text" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" md="4">
                      <v-select v-model="addSubscriptionModel.industryType" :items="industryTypeOptions"
                        :rules="[(v) => !!v || 'Item is required']" label="Industry Type" item-text="englishName"
                        item-value="id" @change="industryTypeChanged" required>
                      </v-select>
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-select v-model="addSubscriptionModel.businessSector" :items="businessSectorsOptions"
                        :rules="[(v) => !!v || 'Item is required']" label="Business Sector" item-text="englishName"
                        item-value="id" :disabled="addSubscriptionModel.industryType == null"
                        @change="businessSectorChanged" required>
                      </v-select>
                    </v-col>

                    <v-col cols="4" md="4">
                      <v-select v-model="addSubscriptionModel.businessSubSector" :items="businessSubSectorsOptions"
                        :rules="[(v) => !!v || 'Item is required']" label="Sub-Sector" item-text="englishName"
                        item-value="id" :disabled="addSubscriptionModel.businessSector == null" required>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" md="4">
                      <v-text-field id="txtContactPerson" class="green-input" v-model="addSubscriptionModel.contactPerson"
                        label="Contact Person" :rules="[rules.required]" type="text" />
                    </v-col>

                    <v-col cols="4" md="4">
                      <v-text-field id="txtContactPersonMail" class="green-input"
                        v-model="addSubscriptionModel.contactPersonMail" label="Contact Person Mail"
                        :rules="[rules.required, rules.emailRule]" type="text" />
                    </v-col>

                    <v-col cols="4" md="4">
                      <v-text-field id="txtContactPersonMobile" class="green-input"
                        v-model="addSubscriptionModel.contactPersonMobile" label="Contact Person Mobile"
                        :rules="[rules.required]" type="text" />
                    </v-col>

                    <!-- <v-col cols="12" class="text-right">
                      <v-btn
                        color="success"
                        class="mr-0"
                        :disabled="!valid"
                        @click="SaveProfile"
                      >
                        Submit Reqeust
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Adddialog = false">
                Cancel</v-btn>
              <v-btn color="blue darken-1" :disabled="!valid" text @click="doAddSubscription">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
          <v-row>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
            </v-text-field>
          </v-row>
          <v-row>
            <v-progress-linear :active="loading" color="green" :indeterminate="loading" absolute bottom height="15">
              Loading... </v-progress-linear>`
          </v-row>
        </v-card-title>

        <v-data-table dense :headers="newFields" :items="items" :search="search" class="elevation-1" expand="true">
          <template v-slot:[`header.customerNumber`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.customerName`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.startDate`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.endDate`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.subscriptionPackage`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.User`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:[`header.Actions`]="{ header }">
            <h6>
              <b> {{ header.text }}</b>
            </h6>
          </template>
          <template v-slot:top>
            <v-dialog v-model="Editdialog" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Edit Subscription for customer
                    {{ editedItem.customerName }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="EditPackageForm" v-model="valid" lazy-validation>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select v-model="editedItem.subscriptionPackage" :items="subscriptionPackages"
                            :rules="[rules.required]" label="Subscription Package" item-text="packageName"
                            item-value="packageName">
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select v-model="editedItem.subscriptionPeriod" :items="subscriptionPeriods"
                            :rules="[rules.required]" label="Subscription Period" @change="selectedPeriodChanged"
                            item-text="text" item-value="value">
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="editedItem.startDate" label="Picker in menu"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" @change="dateChanged"
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.startDate" color="green lighten-1" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-alert>
                            END Date: <b>{{ getDate(evalEndDate) }}</b>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="Editdialog = false">
                    Cancel</v-btn>
                  <v-btn color="blue darken-1" :disabled="!valid" text @click="validatePackage">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">User Info</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field id="username" class="green-input" v-model="usernameval" label="User Name"
                            :error-messages="errors" :rules="[rules.required]" type="text" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field id="password" class="green-input" v-model="editedUserItem.password"
                            label="Password" :rules="[rules.required, rules.password]"
                            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
                            :type="value ? 'text' : 'password'" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field id="confirmPassword" class="green-input" label="confirm Password"
                            :rules="[rules.required, rules.confirmpassword]" :append-icon="valueconfirmed ? 'mdi-eye' : 'mdi-eye-off'
                              " @click:append="() => (valueconfirmed = !valueconfirmed)
    " :type="valueconfirmed ? 'text' : 'password'" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field id="email" class="green-input" v-model="editedUserItem.email" label="E-Mail"
                            :rules="[rules.required, rules.emailRule]" type="text" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field id="telephone" class="green-input" v-model="editedUserItem.telephone"
                            label="Telephone" :rules="[rules.required]" type="text" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-btn color="success" class="mr-0" :disabled="!valid" @click="updateUserDetails">
                            Update
                          </v-btn>
                          <v-btn color="success" class="mr-0" :disabled="!sending" @click="sendUserDetails">
                            Send Email
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-progress-linear :active="UserDetailsloading" color="green" :indeterminate="UserDetailsloading"
                          absolute bottom height="15">
                          Loading... </v-progress-linear>`
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ getDate(new Date(item.startDate)) }}
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ getDate(new Date(item.endDate)) }}
          </template>
          <template v-slot:[`item.User`]="{ item }">
            <v-icon small class="mr-2" @click="editUser(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="sendUserInfo(item)">
              mdi-email
            </v-icon>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small class="mr-2" @click="editPackage(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="ConfirmUpdateStatus(item)" color="red" v-if="item.isActive == 0">
              mdi-brightness-1
            </v-icon>
            <v-icon small class="mr-2" @click="ConfirmUpdateStatus(item)" color="green" v-if="item.isActive == 1">
              mdi-brightness-1
            </v-icon>
            <v-icon small class="mr-2" @click="deleteSubscription(item)" color="red">
              mdi-delete-empty
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No SUbscriptions </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <div class="text-center">
        <v-snackbar v-model="snackbar" timeout="3000">
          {{ alertMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <div class="text-center">
        <v-snackbar v-model="errorsnackbar" timeout="3000">
          {{ alertMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="errorsnackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <v-dialog v-model="approveReqDialog" max-width="500px">
        <v-card :loading="loading" loading-text="Loading... Please wait">
          <v-card-title class="text-h3">ِAre you sure you want to Activate/DeActivate Package?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="updatePackageStatus">Yes</v-btn>
            <v-btn color="green darken-1" text @click="approveReqDialog = false">No</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteConfirm" max-width="500px">
        <v-card :loading=loading loading-text="Loading... Please wait">
          <v-card-title class="text-h3">ِAre you sure you sure you want to delete use ? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteSubscription">Yes</v-btn>
            <v-btn color="green darken-1" text @click="deleteConfirm = false">No</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-progress-linear :active="DeleteConfirmloading" color="green" :indeterminate="DeleteConfirmloading" absolute
            bottom height="15">
            Loading...
          </v-progress-linear>
        </v-card>
      </v-dialog>
      <v-dialog v-model="existingIndustryType" max-width="500px">
        <v-card>
          <v-card-title class="text-h3">Can't update status, economic sector has Industry types, please
            deactivate them first and then try again
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="existingIndustryType = false">Close</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- <b-button variant="outline-primary" v-b-modal.AddSubscription>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add Subscription
        </b-button> -->
    <!-- <b-table
        striped hover
        :items="items"
        :fields="fields"
         ref="SubscriptionsList"
         v-if="items.length > 0">
         <template #cell(startDate)="row">
            {{getDate(new Date(row.value))}}
        </template>
        <template #cell(endDate)="row">
            {{getDate(new Date(row.value))}}
        </template>
        <template #cell(businessSectors) = "row">
            <b-button block variant="primary"  @click="editBusinessSectors(row.item,row.index)" >
                View/Edit
            </b-button>
        </template>
        <template #cell(subscriptionScreens) = "row">
            <b-button block variant="primary"  @click="editSubscriptionScreens(row.item,row.index)" >
                View/Edit
            </b-button>
        </template>

        <template #cell(Details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-secondary">
               Users
               <b-icon-arrow-up v-if="!row.detailsShowing"></b-icon-arrow-up>
               <b-icon-arrow-down v-if="row.detailsShowing" ></b-icon-arrow-down>
            </b-button>
        </template>
        <template #cell(Actions)="row">


             <b-button size="sm" class="mr-2" @click="editSubscription(row.item,row.index)">Edit</b-button>
        </template>
        <template #row-details="row">
           <b-button variant="outline-primary" @click="addUser(row.item,row.index)">
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add User
        </b-button>
           <b-table
          striped hover
          :items="row.item.subscriptionUsers"
          :fields="Users">
            <template #cell(password)="row">
              <template v-for="(x,index) in row.value">
                  <span :key="index">*</span>
              </template>
            </template>
           </b-table>
        </template>
        </b-table> -->
    <div v-if="items.length == 0">
      <center>
        <h2>No Items</h2>
      </center>
    </div>
    <b-modal id="AddSubscription" title="Add Subscription" hide-footer @show="showAddSubscription()" hide-backdrop
      size="xl">
      <form ref="form" @submit.stop.prevent="addSubscription">
        <b-form-group label="Customer Name" label-for="txtCustomerName">
          <b-form-input type="text" id="txtCustomerName" name="txtCustomerName" placeholder="Customer Name"
            v-model="$v.form.customerName.$model" :state="validateState('customerName')"
            aria-describedby="customerName-feedbackk">
          </b-form-input>
          <b-form-invalid-feedback id="customerName-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="4">
              <b-form-group label="Package" label-for="ddlSubscriptionPackages">
                <b-form-select id="ddlSubscriptionPackages" name="ddlSubscriptionPackages"
                  v-model="$v.form.subscriptionPackage.$model" :options="subscriptionPackageOptions"
                  :state="validateState('subscriptionPackage')" aria-describedby="subscriptionPackage-feedback"
                  text-field="packageName" value-field="id" class="mb-2 mr-sm-2 mb-sm-0" @change="selectedPkgChange">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- select Package --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="subscriptionPackage-feedback">This is a required
                  field.</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Start Date" label-for="ddlSubscriptionPackages">
                {{ getDate(this.startDate) }}
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="End Date" label-for="ddlSubscriptionPackages">
                {{ this.endDate != null ? this.getDate(this.endDate) : "" }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="6">
              <b-card-group deck>
                <b-card header="Business Sectors" header-tag="header">
                  <b-form-checkbox-group id="chkBusinessSectors" v-model="$v.form.selectedBusinessSectors.$model"
                    :options="businessSectorsOptions" name="chkBusinessSectors" text-field="englishName" value-field="id"
                    :state="validateState('selectedBusinessSectors')" aria-describedby="selectedBusinessSectors-feedbackk"
                    stacked>
                  </b-form-checkbox-group>
                  <b-form-invalid-feedback id="selectedBusinessSectors-feedback">
                    This is a required field
                  </b-form-invalid-feedback>
                </b-card>
              </b-card-group>
            </b-col>
            <b-col sm="6">
              <b-card-group deck>
                <b-card header="Screens" header-tag="header">
                  <b-form-checkbox-group id="chkScreens" v-model="$v.form.selectedScreens.$model"
                    :options="screensOptions" name="chkScreens" text-field="name" value-field="id"
                    :state="validateState('selectedScreens')" aria-describedby="selectedScreens-feedbackk" stacked>
                  </b-form-checkbox-group>
                  <b-form-invalid-feedback id="selectedScreens-feedback">
                    This is a required field
                  </b-form-invalid-feedback>
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>
        </b-container>
        <b-card-group deck>
          <b-card header="Users" header-tag="header">
            <b-button variant="outline-primary" @click="addUserToNewSubscription">
              <b-icon icon="plus-circle-fill" scale="1" variant="success"></b-icon>
              Add User
            </b-button>
            <b-table striped hover :items="newSubscriptionUsers" :fields="Users">
              <template #cell(password)="row">
                <template v-for="(x, index) in row.value">
                  <span :key="index">*</span>
                </template>
              </template>
            </b-table>
          </b-card>
        </b-card-group>
        <b-button type="submit" variant="primary">Add subscription</b-button>
        <b-button class="ml-2" @click="onReset()">Reset</b-button>
      </form>
    </b-modal>
    <b-modal id="EditSubscription" title="Edit Subscription" size="lg" hide-footer hide-backdrop>
      <form ref="form" @submit.stop.prevent="updateSubscription">
        <b-form-group label="Customer Number" label-for="Id">
          {{ this.form.customerNumber }}
        </b-form-group>
        <b-form-group label="Customer Name" label-for="txtCustomerName">
          <b-form-input type="text" id="txtCustomerName" name="txtCustomerName" placeholder="Customer Name"
            v-model="$v.form.customerName.$model" :state="validateState('customerName')"
            aria-describedby="customerName-feedbackk">
          </b-form-input>
          <b-form-invalid-feedback id="customerName-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="4">
              <b-form-group label="Package" label-for="ddlSubscriptionPackages">
                <b-form-select id="ddlSubscriptionPackages" name="ddlSubscriptionPackages"
                  v-model="$v.form.subscriptionPackage.$model" :options="subscriptionPackageOptions"
                  :state="validateState('subscriptionPackage')" aria-describedby="subscriptionPackage-feedback"
                  text-field="packageName" value-field="id" class="mb-2 mr-sm-2 mb-sm-0" @change="selectedPkgChange">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- select Package --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="subscriptionPackage-feedback">This is a required
                  field.</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Start Date" label-for="ddlSubscriptionPackages">
                {{ getDate(this.startDate) }}
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="End Date" label-for="ddlSubscriptionPackages">
                {{ getDate(this.endDate) }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="6">
              <b-card-group deck>
                <b-card header="Business Sectors" header-tag="header">
                  <b-form-checkbox-group id="chkBusinessSectors" v-model="form.selectedBusinessSectors"
                    :options="businessSectorsOptions" name="chkBusinessSectors" text-field="englishName" value-field="id"
                    stacked>
                  </b-form-checkbox-group>
                </b-card>
              </b-card-group>
            </b-col>
            <b-col sm="6">
              <b-card-group deck>
                <b-card header="Screens" header-tag="header">
                  <b-form-checkbox-group id="chkScreens" v-model="form.selectedScreens" :options="screensOptions"
                    name="chkScreens" text-field="name" value-field="id" stacked>
                  </b-form-checkbox-group>
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>
        </b-container>
        <b-button type="submit" variant="primary">Update</b-button>
      </form>
    </b-modal>
    <b-modal id="AddUser" title="Add User" hide-footer hide-backdrop>
      <form ref="form" @submit.stop.prevent="addSubscriptionUser">
        <b-form-group label="Name" label-for="txtName">
          <b-form-input type="text" id="txtName" name="txtName" placeholder="Name" v-model="$v.userForm.name.$model"
            :state="validateUserState('name')" aria-describedby="name-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="name-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="User Name" label-for="txtUserName">
          <b-form-input type="text" id="txtUserName" name="txtUserName" placeholder="User Name"
            v-model="$v.userForm.username.$model" :state="validateUserState('username')"
            aria-describedby="username-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="username-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Password" label-for="txtPassword">
          <b-form-input type="password" id="txtPassword" name="txtPassword" v-model="$v.userForm.userPassword.$model"
            :state="validateUserState('userPassword')" aria-describedby="userPassword-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="userPassword-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Confirm Password" label-for="txtConfirmPassword">
          <b-form-input type="password" id="txtConfirmPassword" name="txtConfirmPassword"
            v-model="$v.userForm.userConfirmPassword.$model" :state="validateUserState('userConfirmPassword')"
            aria-describedby="userConfirmPassword-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="userConfirmPassword-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="E-Mail" label-for="txtEMail">
          <b-form-input type="text" id="txtEMail" name="txtEMail" placeholder="E-Mail" v-model="$v.userForm.email.$model"
            :state="validateUserState('email')" aria-describedby="email-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="email-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Mobile" label-for="txtMobile">
          <b-form-input type="number" id="txtMobile" name="txtMobile" placeholder="Mobile Number"
            v-model="$v.userForm.mobile.$model" :state="validateUserState('mobile')" aria-describedby="mobile-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="mobile-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button type="submit" variant="primary">Add</b-button>
        <b-button class="ml-2" @click="onResetUser()">Reset</b-button>
      </form>
    </b-modal>
    <b-modal id="EditBusinessSectors" title="Edit Subscription Business Sectors" hide-footer hide-backdrop>
      <form ref="form" @submit.stop.prevent="UpdateBusinessSectors">
        <div id="RolesItemsList">
          <b-form-checkbox-group id="chkUserRoles" v-model="$v.form.selectedBusinessSectors.$model"
            :state="validateState('selectedBusinessSectors')" aria-describedby="selectedBusinessSectors-feedback"
            name="chkUserRoles" stacked>
            <ul>
              <li v-for="role in businessSectorsOptions" :key="role.id">
                <b-form-checkbox :value="role.id">{{
                  role.englishName
                }}</b-form-checkbox>
              </li>
            </ul>
          </b-form-checkbox-group>
          <b-form-invalid-feedback id="selectedBusinessSectors-feedback">
            This is a required field
          </b-form-invalid-feedback>
          <div v-if="businessSectorsDialogError" class="mandatory">
            Please select at least one business sector
          </div>
        </div>
        <div class="d-block text-center">
          <b-button type="submit" variant="primary" size="lg">Update</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="EditScreens" title="Edit Subscription Screens" hide-footer hide-backdrop>
      <form ref="form" @submit.stop.prevent="UpdateSubscriptionScreens">
        <div id="RolesItemsList">
          <b-form-checkbox-group id="chkUserRoles" v-model="$v.form.selectedScreens.$model"
            :state="validateState('selectedScreens')" aria-describedby="selectedScreens-feedback" name="chkUserRoles"
            stacked>
            <ul>
              <li v-for="role in screensOptions" :key="role.id">
                <b-form-checkbox :value="role.id">{{
                  role.name
                }}</b-form-checkbox>
              </li>
            </ul>
          </b-form-checkbox-group>
          <b-form-invalid-feedback id="selectedScreens-feedback">
            This is a required field
          </b-form-invalid-feedback>
          <div v-if="screensDialogError" class="mandatory">
            Please select at least one screen
          </div>
        </div>
        <div class="d-block text-center">
          <b-button type="submit" variant="primary" size="lg">Update</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from 'moment';
export default {
  mixins: [validationMixin],
  watch: {
    dialog(val) {
      val || this.close();
    },
    reportDialog(val) {
      val || this.closeٌReportDialog();
    },
    usernameval: async function (val) {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/Account/UserName`,
        params: {
          UserName: val,
          OriginalUserName: String(this.originalUserName),
          id: this.editedUserItem?.id
        },
      }).then((response) => {
        if (JSON.parse(response.data.status) == true) {
          response.data.result ? console.log("true") : console.log("false");
          this.errors = response.data.result
            ? []
            : ["User name already exists"];
        }
      });
    },
  },
  data() {
    return {
      existingIndustryType: false,
      errorsnackbar: false,
      loading: false,
      UserDetailsloading: false,
      DeleteConfirmloading: false,
      approveReqDialog: false,
      deleteConfirm: false,
      dateMenu: false,
      subscriptionPeriods: [
        { value: 2, text: "Half-Year" },
        { value: 3, text: "Year" },
      ],
      subscriptionPackages: [],

      editedIndex: -1,
      addSubscriptionModel: {
        id: 0,
        legalName: null,
        commercialName: null,
        legalStructure: null,
        yearOfEstablishment: null,
        industryType: null,
        businessSector: null,
        businessSubSector: null,
        contactPerson: null,
        contactPersonMail: null,
        contactPersonMobile: null,
        webSite: null,
        subscriptionPeriod: -1,
        customerName: "",
        customerNumber: -1,
        startDate: moment(String(new Date())).format('YYYY-MM-DD'),
        endDate: null,
        subscriptionPackage: null,
      },
      editedItem: {
        subscriptionPeriod: -1,
        customerName: "",
        customerNumber: -1,
        startDate: moment(String(new Date())).format('YYYY-MM-DD'),
        endDate: null,
        subscriptionPackage: -1,
      },
      Editdialog: false,
      Adddialog: false,
      search: "",
      snackbar: false,
      alertMsg: "",
      value: String,
      valueconfirmed: String,
      dialog: false,
      sending: true,
      valid: true,
      editedUserItem: {
        id: -1,
        customerNumber: -1,
        name: "",
        userName: "",
        password: "",
        email: "",
        mobile: "",
      },
      editedUserIndex: -1,
      usernameval: "",
      originalUserName: "",
      errors: [],
      rules: {
        required: (value) => !!value || "Required Field",
        emailRule: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
          "E-mail must be valid",
        confirmpassword: (value) =>
          value === this.editedUserItem.password ||
          "two passwords do not match",
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters with at least one capital letter, a number and a special character."
          );
        },
      },
      newFields: [
        { value: "customerNumber", text: "customer Number" },
        { value: "customerName", text: "customer Name" },
        { value: "startDate", text: "start Date" },
        { value: "endDate", text: "end Date" },
        { value: "subscriptionPackage", text: "subscription Package" },
        { value: "User", text: "User" },
        { value: "Actions", text: "Actions" },
      ],
      fields: [
        "customerNumber",
        "customerName",
        // 'CustomerClass',
        "startDate",
        "endDate",
        "subscriptionPackage",
        // "businessSectors",
        // "subscriptionScreens",
        "Details",
        "Actions",
      ],
      BusinessSectors: ["Id", "EnglishName", "Selected"],
      FormsAndReports: ["Id", "EnglishName", "Selected"],
      Users: [
        "name",
        "userName",
        "password",
        "email",
        "mobile",
        // "Status",
        // "Actions"
      ],
      items: [],
      userItems: [],
      // usersData:[],
      subscriptionPackageOptions: [],
      businessSectorsOptions: [],
      screensOptions: [],
      legalStructureOptions: [],
      industryTypeOptions: [],
      businessSectorsAllOptions: [],
      businessSubSectorsAllOptions: [],
      businessSubSectorsOptions: [],
      form: {
        selectedBusinessSectors: [],
        selectedScreens: [],
        customerNumber: null,
        customerName: null,
        subscriptionPackage: null,
      },
      userForm: {
        name: null,
        username: null,
        userPassword: null,
        userConfirmPassword: null,
        email: null,
        mobile: null,
      },
      startDate: new Date(),
      endDate: null,
      currentEditedType: null,
      lookups: [],
      currentEditedJobIndex: null,
      businessSectorsDialogError: false,
      screensDialogError: false,
      newSubscriptionUsers: [],
    };
  },
  validations: {
    form: {
      customerName: {
        required,
      },
      subscriptionPackage: {
        required,
      },
      selectedBusinessSectors: {
        required: {
          check() {
            console.log(this.form.selectedBusinessSectors);
            if (
              this.form.selectedBusinessSectors == null ||
              this.form.selectedBusinessSectors.length == 0
            ) {
              return false;
            }
            return true;
          },
        },
      },
      selectedScreens: {
        required,
        // required:{
        //       check(){
        //           if(this.form.selectedScreens == null || this.form.selectedScreens.length ==0){
        //               return false;
        //           }
        //           return true;
        //       }
        //   }
      },
    },
    userForm: {
      name: {
        required,
      },
      username: {
        required,
      },
      userPassword: {
        required: {
          check() {
            if (
              this.userForm.userPassword == null ||
              this.userForm.userPassword.length < 8
            ) {
              return false;
            }
            return true;
          },
        },
      },
      userConfirmPassword: {
        required: {
          check() {
            if (
              this.userForm.userConfirmPassword == null ||
              this.userForm.userPassword !== this.userForm.userConfirmPassword
            ) {
              return false;
            }
            return true;
          },
        },
      },
      email: {
        required: {
          check() {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.userForm.email).toLowerCase());
          },
        },
      },
      mobile: {
        required,
      },
    },
  },
  computed: {
    evalEndDate: function () {
      let date = new Date(this.editedItem.startDate);
      switch (this.editedItem.subscriptionPeriod) {
        case 2:
          date = new Date(date.setMonth(date.getMonth() + 6));
          break;
        case 3:
          date = new Date(date.setMonth(date.getMonth() + 12));
          break;
      }
      return date;
    },
    aevalEndDate: function () {
      let date = new Date(this.addSubscriptionModel.startDate);
      switch (this.addSubscriptionModel.subscriptionPeriod) {
        case 2:
          date = new Date(date.setMonth(date.getMonth() + 6));
          break;
        case 3:
          date = new Date(date.setMonth(date.getMonth() + 12));
          break;
      }
      return date;
    },
  },
  methods: {
    doAddSubscription() {
      const pdata = {
        LegalName: this.addSubscriptionModel.legalName,
        CommercialName: this.addSubscriptionModel.commercialName,
        //LegalStructureId: this.addSubscriptionModel.legalStructure,
        YearOfEstablishment: Number(
          this.addSubscriptionModel.yearOfEstablishment
        ),
        Website: this.addSubscriptionModel.webSite,
        IndustryTypeId: this.addSubscriptionModel.industryType,
        BusinessSectorId: this.addSubscriptionModel.businessSector,
        BusinessSubSectorId: this.addSubscriptionModel.businessSubSector,
        ContactPersonName: this.addSubscriptionModel.contactPerson,
        ContactPersonEMail: this.addSubscriptionModel.contactPersonMail,
        ContactPersonTelNumber: this.addSubscriptionModel.contactPersonMobile,
        PackageId: this.addSubscriptionModel.subscriptionPackage.id,
        subscriptionPeriod: this.addSubscriptionModel.subscriptionPeriod,
        SubscriptionFees:
          this.addSubscriptionModel.subscriptionPackage.SubscriptionFees,
      };
      this.valid = false;
      this.$axios({
        method: "post",
        url: `${this.$baseURL}/Subscription/AddSubscription`,
        headers: { "Content-Type": "application/json" },
        data: pdata,
      }).then((response) => {
        // this.form.id = response.data;
        // this.successfullRegDialog = true
        if (JSON.parse(response.data.status) == true) {
          this.Adddialog = false;
          this.alertMsg = "‘Subscription added successfully";
          this.snackbar = true;
          this.loadSubscriptions();
        } else {
          this.alertMsg = response.data.message;
          this.errorsnackbar = true;
        }
      });
    },
    getRegisterationLookups() {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/CustomerProfile/lookups`,
      }).then((response) => {
        //this.legalStructureOptions = response.data.legalStructures;
        this.industryTypeOptions = response.data.industryTypes;
        this.businessSectorsAllOptions = response.data.businessSectors;
        this.businessSubSectorsAllOptions = response.data.businessSubSectors;
      });
    },
    businessSectorChanged(item) {
      if (
        this.businessSubSectorsAllOptions == null ||
        (this.businessSubSectorsAllOptions != null &&
          this.businessSubSectorsAllOptions.filter(
            (b) => b.businessSectorId == item
          ).length == 0)
      ) {
        this.$axios({
          method: "get",
          url: `${this.$baseURL}/BusinessSubSector/filter`,
          params: {
            businessSectorId: item,
          },
        }).then((response) => {
          console.log("response...........................");
          console.log(response);

          let w = [];
          response.data.result.forEach((businessSubSector) => {
            w.push({
              id: businessSubSector.id,
              businessSectorId: businessSubSector.businessSectorId,
              englishName: businessSubSector.englishName,
              arabicName: businessSubSector.arabicName,
              status: businessSubSector.status,
            });
          });

          if (this.businessSubSectorsAllOptions == null) {
            this.businessSubSectorsAllOptions = w;
          } else {
            this.businessSubSectorsAllOptions =
              this.businessSubSectorsAllOptions.concat(w);
          }

          this.businessSubSectorsOptions =
            this.businessSubSectorsAllOptions.filter(
              (b) => b.businessSectorId == item
            );

          console.log(" this.businessSubSectorsOptions");
          console.log(this.businessSubSectorsOptions);
        });
      } else {
        this.businessSubSectorsOptions =
          this.businessSubSectorsAllOptions.filter(
            (b) => b.businessSectorId == item
          );
      }
    },
    industryTypeChanged(item) {
      this.loading = true;

      if (
        this.businessSectorsAllOptions == null ||
        (this.businessSectorsAllOptions != null &&
          this.businessSectorsAllOptions.filter((b) => b.industryTypeId == item)
            .length == 0)
      ) {
        console.log("item not found so fetching for it");
        this.$axios({
          method: "get",
          url: `${this.$baseURL}/BusinessSector/filter`,
          params: {
            industryTypeId: item,
          },
        }).then((response) => {
          let w = [];
          response.data.result.forEach((businessSector) => {
            w.push({
              id: businessSector.id,
              industryTypeId: businessSector.industryTypeId,
              englishName: businessSector.englishName,
              arabicName: businessSector.arabicName,
              status: businessSector.status,
            });
          });

          if (this.businessSectorsAllOptions == null) {
            this.businessSectorsAllOptions = w;
          } else {
            this.businessSectorsAllOptions =
              this.businessSectorsAllOptions.concat(w);
          }
          console.log("businessSectorsAllOptions");
          console.log(this.businessSectorsAllOptions);
          this.businessSectorsOptions = this.businessSectorsAllOptions.filter(
            (b) => b.industryTypeId == item
          );
          this.loading = false;
        });
      } else {
        this.businessSectorsOptions = this.businessSectorsAllOptions.filter(
          (b) => b.industryTypeId == item
        );
        this.loading = false;
      }
    },
    ConfirmUpdateStatus(item) {
      this.editedItem = item;
      this.editedIndex = this.items.indexOf(item);
      this.approveReqDialog = true;
    },
    confirmDelete(item) {
      this.editedItem = item;
      this.editedIndex = this.items.indexOf(item)
      this.deleteConfirm = true;
    },
    updatePackageStatus() {
      this.$axios({
        method: "put",
        url: `${this.$baseURL}/Subscription/status`,
        params: {
          CustomerNumber: this.editedItem.customerNumber,
          userId: this.editedItem.subscriptionUsers[0].id,
          status: this.editedItem.isActive == 1 ? 0 : 1,
        },
      }).then(() => {
        this.editedItem.isActive = !this.editedItem.isActive;
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.alertMsg = "subscription updated successfully";
        this.approveReqDialog = false;
        this.snackbar = true;
      });
    },
    addDateChanged() {
      let date = new Date(this.addSubscriptionModel.startDate);
      switch (this.addSubscriptionModel.subscriptionPeriod) {
        case 2:
          date = new Date(date.setMonth(date.getMonth() + 6));
          break;
        case 3:
          date = new Date(date.setMonth(date.getMonth() + 12));
          break;
      }
      this.addSubscriptionModel.endDate = date;
    },
    dateChanged() {
      let date = new Date(this.editedItem.startDate);
      switch (this.editedItem.subscriptionPeriod) {
        case 2:
          date = new Date(date.setMonth(date.getMonth() + 6));
          break;
        case 3:
          date = new Date(date.setMonth(date.getMonth() + 12));
          break;
      }
      this.editedItem.endDate = date;
    },
    selectedPeriodChanged(item) {
      let date = new Date(this.editedItem.startDate);
      switch (item) {
        case 2:
          date = new Date(date.setMonth(date.getMonth() + 6));
          break;
        case 3:
          date = new Date(date.setMonth(date.getMonth() + 12));
          break;
      }
      this.editedItem.endDate = date;
    },
    validatePackage() {
      if (this.$refs.EditPackageForm.validate()) {
        this.updatePackage();
      }
    },
    updatePackage() {
      var selectedSubscriptionPackage = this.subscriptionPackages.filter(
        (i) => i.packageName == this.editedItem.subscriptionPackage
      );
      var SubscriptionFees = 0;
      if (this.editedItem.subscriptionPeriod == 2) {
        // half year
        SubscriptionFees = selectedSubscriptionPackage[0].halfYearFees;
      }
      if (this.editedItem.subscriptionPeriod == 3) {
        // yearly subscription
        SubscriptionFees = selectedSubscriptionPackage[0].yearFees;
      }

      this.$axios({
        method: "put",
        url: `${this.$baseURL}/Subscription`,
        data: {
          CustomerNumber: this.editedItem.customerNumber,
          CustomerName: this.editedItem.customerName,
          StartDate: this.editedItem.startDate,
          EndDate: this.editedItem.endDate,
          SubscriptionPackageId: selectedSubscriptionPackage[0].id,
          SubscriptionPeriod: this.editedItem.subscriptionPeriod,
          SubscriptionFees: SubscriptionFees,
        },
      }).then(() => {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.alertMsg = "subscription updated successfully";
        this.Editdialog = false;
        this.snackbar = true; this.loadSubscriptions();
      });
    },
    editPackage(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.Editdialog = true;
    },
    clearFields() { },
    editUser(item) {
      this.editedUserItem = null;
      this.editedUserIndex = -1;
      this.usernameval = null;

      if (item != null && item.subscriptionUsers.length > 0) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        let selectedUser = item.subscriptionUsers[0];
        this.editedUserIndex = this.items.indexOf(item);
        this.editedUserItem = Object.assign({}, selectedUser);
        this.usernameval = selectedUser.userName;
        this.dialog = true;
      }
    },
    sendUserInfo(item) {
      this.editedUserItem = null;
      this.editedUserIndex = -1;
      this.usernameval = null;

      if (item != null && item.subscriptionUsers.length > 0) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        let selectedUser = item.subscriptionUsers[0];
        this.editedUserIndex = this.items.indexOf(item);
        this.editedUserItem = Object.assign({}, selectedUser);
        this.usernameval = selectedUser.userName;
        this.sendUserDetails();
      }
    },
    updateUserDetails() {
      this.UserDetailsloading = true;
      this.$axios({
        method: "put",
        url: `${this.$baseURL}/Account/userdetails`,
        headers: { "Content-Type": "application/json" },
        data: {
          Id: this.editedUserItem.id,
          UserName: this.usernameval,
          Password: this.editedUserItem.password,
          Email: this.editedUserItem.email,
          PhoneNumber: this.editedUserItem.mobile,
        },
      }).then((response) => {
        this.UserDetailsloading = false;
        this.dialog = false;
        if (JSON.parse(response.data.status) == true) {
          this.editedItem.subscriptionUsers[0] = this.editedUserItem;
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.dialog = false;
          this.alertMsg = "User Info updated successfully";
          this.snackbar = true; this.loadSubscriptions();
        } else {
          this.alertMsg = response.data.message;
          this.errorsnackbar = true;
          this.addUserDetailsLoading = false;
        }
      });
    },
    sendUserDetails() {
      this.UserDetailsloading = true;
      this.sending = false;

      this.$axios({
        method: "post",
        url: `${this.$baseURL}/Account/senduserdetails`,
        headers: { "Content-Type": "application/json" },
        data: {
          Id: this.editedUserItem.id,
          UserName: this.usernameval,
          Password: this.editedUserItem.password,
          Email: this.editedUserItem.email,
          PhoneNumber: this.editedUserItem.mobile,
        },
      }).then((response) => {
        this.UserDetailsloading = false;
        this.sending = true;
        this.dialog = false;
        if (JSON.parse(response.data.status) == true) {
          this.editedItem.subscriptionUsers[0] = this.editedUserItem;
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.dialog = false;
          this.alertMsg = "User Info sent successfully";
          this.snackbar = true;
        } else {
          this.alertMsg = response.data.message;
          this.errorsnackbar = true;
          this.addUserDetailsLoading = false;
        }
      });
    },

    UpdateSubscriptionScreens() {
      if (this.form.selectedScreens.length == 0) {
        this.screensDialogError = true;
      } else {
        this.$axios({
          method: "put",
          url: `${this.$baseURL}/Subscription/Screens`,
          data: {
            CustomerNumber:
              this.items[this.currentEditedJobIndex].customerNumber,
            Screens: this.form.selectedBusinessSectors,
          },
        }).then((response) => {
          if (JSON.parse(response.data.status) == true) {
            this.items[this.currentEditedJobIndex].subscriptionScreens =
              this.form.selectedScreens;
            this.$bvModal.hide("EditScreens");
            this.$refs.SubscriptionsList.refresh();
          }
        });
      }
    },
    editSubscriptionScreens(item, index) {
      if (this.lookups.length == 0) {
        this.getSubscriptionLookups();
      }
      this.$nextTick(() => {
        this.screensDialogError = false;
        this.form.selectedScreens = this.items[index].subscriptionScreens.map(
          (b) => b.id
        );
        this.$bvModal.show("EditScreens");
        this.currentEditedJobIndex = index;
      });
    },
    UpdateBusinessSectors() {
      if (this.form.selectedBusinessSectors.length == 0) {
        this.businessSectorsDialogError = true;
      } else {
        this.$axios({
          method: "put",
          url: `${this.$baseURL}/Subscription/BusinessSectors`,
          data: {
            CustomerNumber:
              this.items[this.currentEditedJobIndex].customerNumber,
            BusinessSectors: this.form.selectedBusinessSectors,
          },
        }).then((response) => {
          if (JSON.parse(response.data.status) == true) {
            this.items[this.currentEditedJobIndex].businessSectors =
              this.form.selectedBusinessSectors;
            this.$bvModal.hide("EditBusinessSectors");
            this.$refs.SubscriptionsList.refresh();
          }
        });
      }
    },
    editBusinessSectors(item, index) {
      if (this.lookups.length == 0) {
        this.getSubscriptionLookups();
      }
      this.$nextTick(() => {
        this.businessSectorsDialogError = false;
        this.form.selectedBusinessSectors = this.items[
          index
        ].businessSectors.map((b) => b.id);
        this.$bvModal.show("EditBusinessSectors");
        this.currentEditedJobIndex = index;
      });
    },
    getUsers(item) {
      var subUsers = this.userItems.filter(
        (i) => i.customerNumber == item.customerNumber
      );
      if (subUsers == null || subUsers.length == 0) {
        this.$axios({
          method: "get",
          url: `${this.$baseURL}/Subscription/users?CustomerNumber=${item.customerNumber}`,
          headers: { "Content-Type": "application/json" },
          data: {
            CustomerNumber: item.customerNumber,
          },
        }).then((response) => {
          subUsers = response.data;
          this.SubscriptionsList.push(response.data);
          // this.$refs.SubscriptionsList.refresh();
        });
      }
      return subUsers;
    },
    getUserDetails(state, item, index) {
      if (state == "Show") {
        if (
          item.subscriptionUsers == null ||
          item.subscriptionUsers.length == 0
        ) {
          this.$axios({
            method: "get",
            url: `${this.$baseURL}/Subscription/users?CustomerNumber=${item.customerNumber}`,
            headers: { "Content-Type": "application/json" },
            data: {
              CustomerNumber: item.customerNumber,
            },
          }).then((response) => {
            // console.log(index)
            this.items[index] = response.data;
            // this.$refs.SubscriptionsList.refresh();
          });
        } else {
          console.log(this.items[index].subscriptionUsers);
        }
      }
      return state;
    },
    addUserToNewSubscription() {
      this.userForm.name = null;
      this.userForm.username = null;
      this.userForm.userPassword = null;
      this.userForm.userConfirmPassword = null;
      this.userForm.msgBoxConfirm = null;
      this.userForm.email = null;
      this.userForm.mobile = null;
      this.form.customerNumber = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.currentUserSubscription = null;
      this.currentEditedSector = -1;
      this.$bvModal.show("AddUser");
    },
    addUser(item, index) {
      this.userForm.name = null;
      this.userForm.username = null;
      (this.userForm.userPassword = null),
        (this.userForm.userConfirmPassword = null);
      (this.userForm.msgBoxConfirm = null), (this.userForm.email = null);
      this.userForm.mobile = null;
      this.form.customerNumber = item.customerNumber;
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.currentUserSubscription = item;
      this.currentEditedSector = index;
      this.$bvModal.show("AddUser");
    },
    addSubscriptionUser() {
      this.$v.userForm.$touch();
      if (this.$v.userForm.$anyError) {
        return;
      }

      //validate that this user name is not taken before
      this.checkUserExists(this.userForm.username).then((result) => {
        console.log(result);
        if (Boolean(result) == true) {
          this.$bvModal.msgBoxOk("User already exists", {
            title: "error",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
          return;
        }
        if (this.currentUserSubscription != null) {
          //editing or addig to existing supscription
          this.$axios({
            method: "post",
            url: `${this.$baseURL}/Subscription/Users`,
            headers: { "Content-Type": "application/json" },
            data: {
              CustomerNumber: this.currentUserSubscription.customerNumber,
              Name: this.userForm.name,
              UserName: this.userForm.username,
              Password: this.userForm.userPassword,
              EMail: this.userForm.email,
              PhoneNumber: this.userForm.mobile,
              StatusId: 1,
            },
          }).then((response) => {
            if (JSON.parse(response.data.status) == true) {
              this.$nextTick(() => {
                this.$bvModal.msgBoxOk("User Added Successfully", {
                  title: "success",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  headerClass: "p-2 border-bottom-0",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                });

                this.items[this.currentEditedSector] = response.data.users;
                this.$bvModal.hide("AddUser");
              }); this.loadSubscriptions();
            } else {
              this.$bvModal.msgBoxOk(response.data.message, {
                title: "error",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
              });
            }
          });
        } else {
          //adding to new subscription
          let user = this.newSubscriptionUsers.filter(
            (u) => u.userName == this.userForm.username
          );
          console.log(user);
          if (user != null && user.length > 0) {
            this.$bvModal.msgBoxOk("User already added", {
              title: "error",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            return;
          }
          this.newSubscriptionUsers.push({
            customerNumber: -1,
            name: this.userForm.name,
            userName: this.userForm.username,
            password: this.userForm.userPassword,
            email: this.userForm.email,
            mobile: this.userForm.mobile,
            statusId: 1,
          });
          this.$bvModal.hide("AddUser");
        }
      });
    },
    editSubscription(item, index) {
      if (this.lookups.length == 0) {
        this.getSubscriptionLookups();
      }
      this.$nextTick(() => {
        console.log(item.endDate);
        this.form.customerNumber = item.customerNumber;
        this.form.customerName = item.customerName;
        this.form.subscriptionPackage = this.subscriptionPackageOptions.filter(
          (o) => o.packageName == item.subscriptionPackage
        )[0].id;
        this.selectedBusinessSectors = item.businessSectors.map((b) => b.id);
        this.selectedScreens = item.subscriptionScreens.map((s) => s.id);
        this.startDate = new Date(item.startDate);
        this.endDate = new Date(item.endDate);

        this.$bvModal.show("EditSubscription");
        this.currentEditedSector = index;
      });
    },
    checkUserExists: function (userName) {
      return this.$axios({
        method: "get",
        url: `${this.$baseURL}/Subscription/UserCheck?UserName=${userName}`,
        headers: { "Content-Type": "application/json" },
      }).then((response) => {
        if (JSON.parse(response.data.status) == true) {
          return response.data.userFound;
        } else {
          return false;
        }
      });
    },
    updateSubscription() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$axios({
        method: "put",
        url: `${this.$baseURL}/Subscription`,
        data: {
          CustomerNumber: this.form.customerNumber,
          CustomerName: this.form.customerName,
          StartDate: this.startDate,
          EndDate: this.endDate,
          SubscriptionPackageId: this.form.subscriptionPackage,
          SubscriptionBusinessSectors: this.businessSectorsOptions
            .filter((o) => this.selectedBusinessSectors.includes(o.id))
            .map((u) => ({
              BusinessSectorId: u.id,
            })),
          SubscriptionScreens: this.screensOptions
            .filter((o) => this.selectedScreens.includes(o.id))
            .map((u) => ({
              ScreenrId: u.id,
            })),
          SubscriptionUsers:
            this.items[this.currentEditedSector].SubscriptionUsers,
        },
      }).then((result) => {
        this.items = result.data;
        this.$bvModal.hide("EditSubscription");
      });
    },
    deleteSubscription(item) {
      this.$bvModal.msgBoxConfirm("Are you sure?").then((value) => {
        if (value == true) {
          this.$axios({
            method: "delete",
            url: `${this.$baseURL}/Subscription`,
            data: { CustomerNumber: item.customerNumber },
          }).then((result) => {
            this.items = result.data;
          });
        }
      });
    },
    addSubscription() {
      console.log(this.form.selectedBusinessSectors);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$axios({
        method: "post",
        url: `${this.$baseURL}/Subscriptions`,
        headers: { "Content-Type": "application/json" },
        data: {
          CustomerName: this.form.customerName,
          StartDate: new Date(),
          endDate: this.endDate,
          SubscriptionPackageId: this.form.subscriptionPackage,
          SubscriptionBusinessSectors: this.businessSectorsOptions
            .filter((o) => this.form.selectedBusinessSectors.includes(o.id))
            .map((u) => ({
              BusinessSectorId: u.id,
            })),
          SubscriptionScreens: this.screensOptions
            .filter((o) => this.form.selectedScreens.includes(o.id))
            .map((u) => ({
              ScreenrId: u.id,
            })),
          Users: this.newSubscriptionUsers.map((u) => ({
            CustomerNumber: -1,
            Name: u.name,
            UserName: u.userName,
            Password: u.password,
            EMail: u.email,
            PhoneNumber: u.mobile,
            StatusId: 1,
          })),
          //selectedScreens
        },
      }).then((response) => {
        this.$nextTick(() => {
          this.$bvModal.hide("AddSubscription");
        });

        this.$bvModal.msgBoxOk("New Subscription added successfully", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });

        this.items = response.data;
      });
    },
    selectedPkgChange(val) {
      let selectedPkg = this.subscriptionPackageOptions.filter(
        (o) => o.id == val
      )[0];
      switch (selectedPkg.packagePeriod) {
        case 1: {
          const date = new Date(this.startDate);
          date.setDate(date.getDate() + 7);
          this.endDate = date;
          break;
        }
        case 2: {
          const date = new Date(this.startDate);
          date.setMonth(date.getMonth() + 1);
          this.endDate = date;
          break;
        }
        case 3: {
          const date = new Date(this.startDate);
          date.setMonth(date.getMonth() + 3);
          this.endDate = date;
          break;
        }
        case 4: {
          const date = new Date(this.startDate);
          date.setMonth(date.getMonth() + 6);
          this.endDate = date;
          break;
        }
      }
    },
    getDate(date) {
      let today = new Date();
      if (date != null) {
        today = date;
      }
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    getSubscriptionLookups() {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/Subscription/lookups`,
      }).then((response) => {
        this.subscriptionPackageOptions = response.data.subscriptionPackages;
        this.businessSectorsOptions = response.data.businessSectors;
        this.screensOptions = response.data.screens;
      });
    },
    showAddSubscription() {
      if (this.lookups.length == 0) {
        this.getSubscriptionLookups();
      }
      this.onReset();
    },
    onReset() {
      this.form = {
        customerNumber: null,
        customerName: null,
        subscriptionPackage: null,
      };
      this.startDate = new Date();
      this.endDate = null;
      this.form.selectedBusinessSectors = [];
      this.form.selectedScreens = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.newSubscriptionUsers = [];
    },
    onResetUser() {
      this.userForm.name = null;
      this.userForm.username = null;
      (this.userForm.userPassword = null), (this.userForm.email = null);
      this.userForm.mobile = null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateUserState(name) {
      const { $dirty, $error } = this.$v.userForm[name];
      return $dirty ? !$error : null;
    },
    loadSubscriptions() {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/Subscription`,
        headers: { "Content-Type": "application/json" },
      }).then((response) => {
        this.items = response.data;
        for (var i in this.items) {
          this.items[i].startDate = this.getDate(
            new Date(this.items[i].startDate)
          );
        }

        console.log("this.items");
        console.log(this.items);
      });
    },
    loadSubscriptionpackages() {
      this.loading = true;
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/SubscriptionPackage`,
      }).then((response) => {
        this.loading = false;
        this.subscriptionPackages = response.data;
      });
    },
  },
  mounted() {
    this.loadSubscriptionpackages();
    this.loadSubscriptions();

    this.getRegisterationLookups();
  },
};
</script>

<style scoped>
#RolesItemsList {
  background: #d1e4ff;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 10px 20px;
}

#RolesItemsList ul {
  padding: 0;
  list-style-type: none;
}

#RolesItemsList li {
  margin-right: 10px;
  margin-top: 10px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
}
</style>